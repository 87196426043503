import "./App.css";
import QRCode from "react-qr-code";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import GhoomakarRasoi from "./GhoomakarRasoi";
import TunnaBookCafe from "./TunnaBookCafe";

const Home = () => {
  console.log(`${window.location.href}tunna'scafe`);
  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    console.log(svg);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    console.log(img);
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    console.log(img.src);
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    console.log(img.src);
  };
  return (
    <>
      <main className="main-center">
        <h1 className="h1">Grorapid QR CODE generator </h1>
        <div>
          {/* <QRCode id="QRCode" className='qr-code-box' value={`${window.location.href}tunnasbookcafe"`} /> */}
          <QRCode
            id="QRCode"
            className="qr-code-box"
            value={`https://www.grorapid.com/qr-generator`}
          />
        </div>
        <input
          type="button"
          style={{ display: "none" }}
          value="Download QR"
          className="hidden"
          onClick={onImageCownload}
        />
        <p className="brand-text ">grorapid.com/qr-generator</p>
      </main>
    </>
  );
};

const RasoiMenu = (props) => {
  const { images } = props;
  return (
    <>
      <main className="menu-page">
        {images.map((image) => {
          return (
            <img
              key={image.name}
              src={image.url}
              alt={image.name}
              height={"100%"}
              width={"100%"}
            />
          );
        })}
      </main>
    </>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="ghoomakar-rasoi-qr" element={<GhoomakarRasoi />} />
          <Route path="tunnasbookcafe-qr" element={<TunnaBookCafe />} />
          <Route path="tunnasbookcafe" element={<RasoiMenu  images={[{name: 'tunnasbookcafe-1', url: "/tunnasbookcafe-1.jpg"}, {name: 'tunnasbookcafe-2', url: "/tunnasbookcafe-2.jpg"}]}/>} />
          <Route path="ghoomakar-rasoi" element={<RasoiMenu  images={[{name: 'ghoomakar-rasoi-1', url: "/ghoomakar-rasoi-1.jpg"}, {name: 'ghoomakar-rasoi-2', url: "/ghoomakar-rasoi-2.jpg"}]}/>} />
          <Route path="" element={<RasoiMenu />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
