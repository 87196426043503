import QRCode from "react-qr-code";

const GhoomakarRasoi = () => {
  console.log(`${window.location.href}tunna'scafe`);
  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    console.log(svg);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    console.log(img);
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    console.log(img.src);
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    console.log(img.src);
  };
  return (
    <>
      <main className="main-center">
        <h1 className="h1">Ghoomakad Rasoi</h1>
        <div>
          <QRCode
            id="QRCode"
            className="qr-code-box"
            value={`https://qr-code.grorapid.com/ghoomakar-rasoi`}
          />
        </div>
        <input type="button"  style={{display: 'none'}} value="Download QR" onClick={onImageCownload} />
        <p className="brand-text">grorapid.com/qr-generator</p>
      </main>
    </>
  );
};

export default GhoomakarRasoi;
